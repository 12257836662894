export const swapTable = {
  '/therapieangebot/':
    'https://cara.app.link/wdEbvMwCh2?utm_source=web&utm_medium=intext&utm_campaign=de',
  '/therapieangebot-orig/':
    'https://cara.app.link/wdEbvMwCh2?utm_source=web&utm_medium=intext&utm_campaign=de',
  '/therapieangebot/?source=hyperlink':
    'https://cara.app.link/wdEbvMwCh2?utm_source=web&utm_medium=intext&utm_campaign=de',
  '/therapieangebot/?source=PSlink':
    'https://cara.app.link/wdEbvMwCh2?utm_source=web&utm_medium=intext&utm_campaign=de',
  '/therapieangebot/?utm_source=darmflora':
    'https://cara.app.link/wdEbvMwCh2?utm_source=web&utm_medium=intext&utm_campaign=de',
};
