import React from 'react';
import Image from 'gatsby-image';
import { ContentfulSidebar } from '../graphql-types';

interface Props {
  sidebar: ContentfulSidebar;
  locale: string;
}

const Sidebar: React.FC<Props> = ({ sidebar, locale }) => {
  if (!sidebar) {
    return null;
  }

  return (
    <div className="side-bar">
      {locale === 'de' && (
        <div className="block margin-top-20">
          {sidebar.bottomImage && (
            <a href={sidebar.bottomImageLink} target="blank">
              <Image
                fluid={sidebar.bottomImage.fluid}
                alt={sidebar.bottomImage.title}
              />
            </a>
          )}
        </div>
      )}
      <div className="block margin-top-20">
        {sidebar.topImage && (
          <Image fluid={sidebar.topImage.fluid} alt={sidebar.topImage.title} />
        )}
        <div className="block_text">
          <div
            css={{
              h2: {
                fontSize: 21,
                fontWeight: 600,
                marginTop: 0,
              },
            }}
            dangerouslySetInnerHTML={{
              __html: sidebar.content?.childMarkdownRemark.html,
            }}
            className="sidebar-content"
          />

          <a href={sidebar.buttonLink} target="blank">
            <button className="margin-top-20">{sidebar.buttonText}</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
