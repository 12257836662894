// Ugly as heck - used to inject the markup into string containing HTML
export default ({
  link,
  title,
  cta,
  banner,
}: {
  link: string;
  title: string;
  cta: string;
  banner: string;
}) => `
<a href="${link}" target="_blank" rel="noopener noreferrer" class="b__link">
<div class="b__img" style="background-image:linear-gradient(90deg,rgba(0,0,0,0.3),rgba(0,0,0,0.1)), url(${banner})";>
<span class="b__title">${title}</span>
<div class="b__btn"><span class="b__btntext">${cta}</span></div>
</div></a>
`;
