import React from 'react';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';
import {
  injectIntl,
  FormattedMessage,
  WrappedComponentProps,
  IntlShape,
} from 'react-intl';
import browserLang from 'browser-lang';

import BlogLayout from '../components/BlogLayout';
import SEO from '../components/SEO';
import Sidebar from '../components/Sidebar';
import banner from '../images/banner-01.png';
import { swapTable } from '../utils/swapTable';
import { replaceTable } from '../utils/replaceTable';
import injectBanner from '../utils/injectBanner';
import BannerStyles from '../styles/BannerStyles';

/**
 * Sanitizes article content - reizdarm.one aftermath 🤯
 */
export const prepareHtml = (body: string, intl: IntlShape) => {
  let output = body;
  const lang = intl.locale;
  // Replace to dropdowns with source
  output = output.replace(
    /(?:<p>)?(\[mk_toggle title=”(.*)” icon=”moon-box-remove” style=”simple”\])(?:<\/p>)?((.|\n)*)(?:<p>)?(\[\/mk_toggle\])(?:<\/p>)?/g,
    '<div class="tabs rect"><div class="tab"><input type="checkbox" id="tab-$2"><label class="tab-label" for="tab-$2">$2</label><div class="tab-content">$3</div></div></div>'
  );

  output = output.replace(
    /\[vc_accordions heading_title=”(.*)” open_toggle=”-1″ container_bg_color=”#ffffff” responsive=”false”\]\[vc_accordion_tab title=”(.*)”\]<\/p>((.|\n)*)\[\/vc_accordion_tab]\[\/vc_accordions]/g,
    '<h2>$1</h2><div class="tabs rect"><div class="tab"><input type="checkbox" id="tab-$2"><label class="tab-label" for="tab-$2">$2</label><div class="tab-content">$3</div></div></div>'
  );

  output = output.replace(
    /\[vc_accordions heading_title=”(.*)” open_toggle=”-1″ container_bg_color=”#ffffff” responsive=””\]\[vc_accordion_tab title=”(.*)”\]<\/p>((.|\n)*)\[\/vc_accordion_tab]\[\/vc_accordions]/g,
    '<h2>$1</h2><div class="tabs rect"><div class="tab"><input type="checkbox" id="tab-$2"><label class="tab-label" for="tab-$2">$2</label><div class="tab-content">$3</div></div></div>'
  );
  // Replaces links to reizdarm.one/therapieangebot with deep link from branch
  const matches = output.match(/href="(.*?)"/g);
  if (matches) {
    const links = matches
      .map((href) => href.replace('href=', ''))
      .map((href) => href.replace(/"/g, ''));
    links
      .filter((el) =>
        el.match(
          /(http[s]?:\/\/){0,1}(www\.){0,1}reizdarm.one\/therapieangebot(.*)/gi
        )
      )
      .forEach((match) => {
        output = output.replace(
          match,
          'https://cara.app.link/wdEbvMwCh2?utm_source=web&utm_medium=intext&utm_campaign=de'
        );
      });
  }
  // replace all reizdarm.one links to empty link (local)
  output = output.replace(/http(?:s)?:\/\/(www\.)?reizdarm\.one/g, '');
  output = output.replace(
    /<banner>/,
    injectBanner({
      title: intl.formatMessage({
        id: 'articles.banner.title',
        defaultMessage: 'CARA CARE supports you with your digestive problems',
      }),
      cta: intl.formatMessage({
        id: 'articles.banner.cta',
        defaultMessage: 'Get the App',
      }),
      link: intl.formatMessage({
        id: 'articles.banner.link',
        defaultMessage: 'https://cara.app.link/QyGYmQzxD4',
      }),
      banner,
    })
  );

  if (lang === 'de') {
    // all the values of `href` attributes without quotes
    let paths = null;
    const matches = output.match(/href="(.*?)"/g);

    if (matches) {
      paths = matches
        .map((href) => href.replace('href=', ''))
        .map((href) => href.replace(/"/g, ''));
    }

    if (!paths) {
      return output;
    }

    for (let i = 0; i < paths.length; i++) {
      const match = matches[i];
      const path = paths[i];
      if (swapTable.hasOwnProperty(path)) {
        output = output.replace(
          match,
          `href="${swapTable[path as keyof typeof swapTable]}"`
        );
      } else if (replaceTable.hasOwnProperty(path)) {
        output = output.replace(
          match,
          `href="/de${replaceTable[path as keyof typeof replaceTable]}"`
        );
      }
    }
  }

  return output;
};

interface OwnProps {
  data: {
    contentfulBlogPost: import('../graphql-types').ContentfulBlogPost;
    author: import('../graphql-types').ContentfulAuthorConnection;
    sidebar: import('../graphql-types').ContentfulSidebarConnection;
  };
  pageContext: {
    locale: string;
    categories: { slug: string; title: string }[];
  };
}

type Props = OwnProps & WrappedComponentProps;

interface State {
  inhaltHtml: string;
}

class BlogPostTemplate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      inhaltHtml: '',
    };
  }

  componentDidMount() {
    // FIXME: https://www.gatsbyjs.org/packages/gatsby-transformer-remark/?=remark#getting-table-of-contents
    this.inhalt();
    // ugly hack 😢
    // blog pages aren't generated for en-GB locale
    // replace all `com.gohidoc.cara` app download links with `com.gohidoc.caraeu` download links
    // if preffered browser locale is en-GB
    const prefferedLang = browserLang();
    if (prefferedLang === 'en') {
      // wrap everything with try/catch so that the page doesn't blow up
      // if the browser doesn't support document.querySelectorAll
      try {
        const sidebarLinks = document.querySelectorAll<HTMLAnchorElement>(
          'a[href="https://cara.app.link/content_sidebar_US"]'
        );
        const bannerLinks = document.querySelectorAll<HTMLAnchorElement>(
          'a[href="https://cara.app.link/content_banner_US"]'
        );
        // some browsers don't support NodeList.prototype.forEach()
        for (let i = 0; i < sidebarLinks.length; i++) {
          sidebarLinks[i].href = 'https://cara.app.link/content_sidebar_uk';
        }

        for (let i = 0; i < bannerLinks.length; i++) {
          bannerLinks[i].href = 'https://cara.app.link/content_banner_uk';
        }
      } catch {}
    }
  }

  inhalt() {
    const inhalt = Array.from(document.getElementsByTagName('h2'));
    if (inhalt) {
      let inhaltHTML = '';
      inhalt.forEach((el, index) => {
        if (index !== inhalt.length - 1) {
          el.id = 'inhalt-' + index;
          inhaltHTML = inhaltHTML.concat(
            '<a href="#' +
              el.id +
              '" ><span class="tab-label" key="' +
              index +
              '">' +
              el.innerHTML +
              '</span></a>'
          );
        }
      });

      this.setState({
        inhaltHtml: inhaltHTML,
      });
    }
  }

  render() {
    const { contentfulBlogPost: post, sidebar } = this.props.data;
    const { locale } = this.props.pageContext;
    const defaultAuthor = this.props.data.author.nodes[0];
    const author = post.author !== null ? post.author : defaultAuthor;
    const breadCrumbCategories = this.props.pageContext.categories;

    return (
      <BlogLayout>
        <BannerStyles />
        <SEO
          title={post.seoTitle || post.title}
          lang={locale}
          description={post.metadescription}
        />

        <div className="wrapper">
          <div className="main article">
            <div className="content">
              <div className="cover">
                {post.heroImage && (
                  <Image
                    fluid={post.heroImage.fluid}
                    alt={post.title}
                    title={post.title}
                  />
                )}
              </div>

              <div className="article-header">
                <p>
                  <span>
                    {breadCrumbCategories.map((el, index) => {
                      if (index !== breadCrumbCategories.length - 1) {
                        return (
                          <span key={index}>
                            <Link to={el.slug}>{el.title}</Link> &gt;{' '}
                          </span>
                        );
                      } else {
                        return (
                          <Link key={index} to={el.slug}>
                            {el.title}
                          </Link>
                        );
                      }
                    })}
                  </span>
                </p>
                <h1>{post.title}</h1>

                <div className="author-info">
                  <img
                    className="author-info-icon"
                    src={author.avatar.fluid.src}
                    alt={author.name}
                  />
                  <p>
                    <span className="author-info-link">{author.name}</span>
                  </p>
                </div>
              </div>
              <div className="tabs rect">
                <div className="tab">
                  <input type="checkbox" id="tab1" />
                  <label className="tab-label" htmlFor="tab1">
                    <FormattedMessage
                      id="blog.tableOfContents"
                      defaultMessage="Table of contents"
                    />
                  </label>
                  <div className="tab-content">
                    <section
                      dangerouslySetInnerHTML={{
                        __html: this.state.inhaltHtml,
                      }}
                    />
                  </div>
                </div>
              </div>

              <section
                id="post-content"
                dangerouslySetInnerHTML={{
                  __html: prepareHtml(
                    this.props.data.contentfulBlogPost.body.childMarkdownRemark
                      .html,
                    this.props.intl
                  ),
                }}
              />

              {author && (
                <div className="author-info-huge">
                  <img
                    className="author-info-huge-image"
                    src={author.avatar.fluid.src}
                    alt={author.name}
                  />
                  <div className="author-info-huge-rect">
                    <p className="author-info-huge-header">
                      <span className="author-info-link">{author.name}</span>
                    </p>
                    <div
                      className="author-info-huge-text"
                      dangerouslySetInnerHTML={{
                        __html: author.shortBio.childMarkdownRemark.html,
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            {sidebar && sidebar.nodes && sidebar.nodes.length && (
              <Sidebar
                sidebar={sidebar.nodes[0]}
                locale={this.props.pageContext.locale}
              />
            )}
          </div>
        </div>
      </BlogLayout>
    );
  }
}

export const pageQuery = graphql`
  query ContentfulBlogPostBySlug($id: String!, $locale: String!) {
    site {
      siteMetadata {
        de {
          title
          description
          author
        }
      }
    }
    author: allContentfulAuthor(
      filter: { slug: { eq: "andre-sommer" }, node_locale: { eq: $locale } }
      limit: 1
    ) {
      nodes {
        name
        node_locale
        shortBio {
          childMarkdownRemark {
            html
          }
        }
        avatar {
          fluid {
            src
          }
        }
      }
    }
    contentfulBlogPost(id: { eq: $id }) {
      title
      slug
      metadescription
      seoTitle
      category {
        title
        slug
        blog_post {
          title
          slug
        }
        category {
          title
          slug
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        id
        title
        description
        fluid(maxWidth: 777) {
          ...GatsbyContentfulFluid
        }
      }
      author {
        slug
        name
        avatar {
          fluid {
            src
          }
        }
        shortBio {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    sidebar: allContentfulSidebar(
      filter: { node_locale: { eq: $locale } }
      limit: 1
    ) {
      nodes {
        buttonText
        content {
          childMarkdownRemark {
            html
          }
        }
        topImage {
          fluid(maxWidth: 715) {
            ...GatsbyContentfulFluid
          }
        }
        bottomImage {
          fluid(maxWidth: 715) {
            ...GatsbyContentfulFluid
          }
        }
        bottomImageLink
        buttonLink
      }
    }
  }
`;

export default injectIntl(BlogPostTemplate);
