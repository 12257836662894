import React from 'react';
import { Global } from '@emotion/core';
import global from '../styles/global';

import LayoutHeader from './Layout/LayoutHeader';
import Footer from './Homepage/Footer';
import { useIntl } from 'react-intl';

interface Props {
  children: React.ReactNode;
}

const BlogLayout: React.FC<Props> = ({ children }) => {
  const intl = useIntl();

  return (
    <>
      <Global styles={global} />
      <LayoutHeader
        hideLanguageSwap={false}
        isNewLayout={false}
        isDarkHeader={false}
      />
      <main
        css={{
          margin: '10px 0',
          transition: 'margin-top 0.5s',
          marginTop: 'calc(10rem + 30px)',
          '@media (max-width: 425px)': {
            marginTop:
              intl.locale === 'de'
                ? 'calc(13rem + 60px)'
                : 'calc(10rem + 60px)',
          },
        }}
      >
        {children}
      </main>
      <Footer />
    </>
  );
};

export default BlogLayout;
