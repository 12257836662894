import React from 'react';
import { Global } from '@emotion/core';
import presets from '../styles/presets';
import banner from '../images/banner-01.png';

const GlobalBannerStyles = () => {
  return (
    <Global
      styles={{
        '.b__link': {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          color: 'inherit',
          marginBottom: '1rem',
          '&:hover': {
            color: 'inherit',
            textDecoration: 'none',
          },
        },
        '.b__img': {
          padding: 20,
          backgroundImage: `linear-gradient(90deg,rgba(0,0,0,0.3),rgba(0,0,0,0.1)), url(${banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          [presets.md]: {
            padding: '10px 225px 23px 18px',
          },
        },
        '.b__title': {
          display: 'block',
          marginBottom: '1rem',
          fontSize: 20,
          fontWeight: 500,
          lineHeight: 1.45,
          letterSpacing: '-0.67px',
          color: '#fff',
          textShadow: '1px 1px 3px rgba(0,0,0,0.2)',
        },
        '.b__btn': {
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 6,
          backgroundColor: '#00b3a7',
          maxWidth: 142,
        },
        '.b__btntext': {
          fontSize: 17,
          fontWeight: 500,
          letterSpacing: '-0.26px',
          color: '#fff',
        },
        iframe: {
          border: '0!important',
        },
      }}
    />
  );
};

export default GlobalBannerStyles;
