export const replaceTable = {
  '/behandlung/alternativmedizin/bachblueten-tropfen/':
    '/behandlung/alternativ/bachblueten-tropfen/',
  '/behandlung/alternativmedizin/bachblueten/':
    '/behandlung/alternativ/bachblueten/',
  '/behandlung/alternativmedizin/beifuss/': '/behandlung/alternativ/beifuss/',
  '/behandlung/colon-hydro-therapie/':
    '/behandlung/alternativ/colon-hydro-therapie/',
  '/behandlung/alternativmedizin/darmkur/': '/behandlung/alternativ/darmkur/',
  '/behandlung/darmsanierung-nach-antibiotika-einnahme/':
    '/behandlung/alternativ/darmsanierung-nach-antibiotika/',
  '/behandlung/einlauf-darmreinigung/':
    '/behandlung/alternativ/einlauf-darmreinigung/',
  '/behandlung/alternativmedizin/entgiftungskur/':
    '/behandlung/alternativ/entgiftungskur/',
  '/behandlung/alternativmedizin/glaubersalz/':
    '/behandlung/alternativ/glaubersalz/',
  '/behandlung/alternativmedizin/heilfasten-anleitung/':
    '/behandlung/alternativ/heilfasten-anleitung/',
  '/behandlung/alternativmedizin/heilfasten-erfahrungsbericht/':
    '/behandlung/alternativ/heilfasten-erfahrungsbericht/',
  '/behandlung/alternativmedizin/mariendistel-wirkung/':
    '/behandlung/alternativ/mariendistel-wirkung/',
  '/behandlung/alternativmedizin/mariendistel/':
    '/behandlung/alternativ/mariendistel/',
  '/behandlung/alternativmedizin/mariendistelsamen/':
    '/behandlung/alternativ/mariendistelsamen/',
  '/behandlung/alternativmedizin/msm-kritik/':
    '/behandlung/alternativ/msm-kritik/',
  '/behandlung/alternativmedizin/msm-pulver/aa':
    '/behandlung/alternativ/msm-pulver/',
  '/behandlung/alternativmedizin/msm-wirkung/':
    '/behandlung/alternativ/msm-wirkung/',
  '/behandlung/alternativmedizin/natuerliche-abfuehrmittel/':
    '/behandlung/alternativ/natuerliche-abfuehrmittel/',
  '/behandlung/alternativmedizin/okoubaka/': '/behandlung/alternativ/okoubaka/',
  '/behandlung/alternativmedizin/ozontherapie/':
    '/behandlung/alternativ/ozontherapie/',
  '/behandlung/alternativmedizin/schuessler-salze/nr-10-zur-entgiftung/':
    '/behandlung/alternativ/schuessler-salz-10/',
  '/behandlung/alternativmedizin/schuessler-salze/nr-11-silicea/':
    '/behandlung/alternativ/schuessler-salz-11/',
  '/behandlung/alternativmedizin/schuessler-salze/schuessler-salz-nr-4-kaliumchlorat/':
    '/behandlung/alternativ/schuessler-salz-4/',
  '/behandlung/alternativmedizin/schuessler-salze/nr-7-magnesium-phosphoricum/':
    '/behandlung/alternativ/schuessler-salz-7/',
  '/behandlung/alternativmedizin/schuessler-salze/nr-8-natriumchlorid/':
    '/behandlung/alternativ/schuessler-salz-8/',
  '/behandlung/alternativmedizin/schuessler-salze/nr-9-natriumphosphat/':
    '/behandlung/alternativ/schuessler-salz-9/',
  '/behandlung/alternativmedizin/schuessler-salze/ueberblick/':
    '/behandlung/alternativ/schuessler-ueberblick/',
  '/behandlung/alternativmedizin/zeolith/': '/behandlung/alternativ/zeolith/',
  '/ernaehrung/ernaehrungsberatung-berlin/':
    '/behandlung/ernaehrungstherapie/ernaehrungsberatung-berlin/',
  '/ernaehrung/ernaehrungsberatung-hamburg/':
    '/behandlung/ernaehrungstherapie/ernaehrungsberatung-hamburg/',
  '/ernaehrung/ernaehrungsberatung-kosten/':
    '/behandlung/ernaehrungstherapie/ernaehrungsberatung-kosten/',
  '/ernaehrung/ernaehrungsberatung-krankenkassen/':
    '/behandlung/ernaehrungstherapie/ernaehrungsberatung-krankenkassen/',
  '/ernaehrung/online-ernaehrungsberatung/':
    '/behandlung/ernaehrungstherapie/online-ernaehrungsberatung/',
  '/medikamente/abfuehrmittel/': '/behandlung/medikamente/abfuehrmittel/',
  '/allgemein/amylase-trypsin-inhibitoren/':
    '/behandlung/medikamente/amylase-trypsin-inhibitoren/',
  '/medikamente/antazida/': '/behandlung/medikamente/antazida/',
  '/ernaehrung/histaminintoleranz/antihistaminika/':
    '/behandlung/medikamente/antihistaminika/',
  '/medikamente/bromelain/': '/behandlung/medikamente/bromelain/',
  '/medikamente/buscopan-plus/': '/behandlung/medikamente/buscopan-plus/',
  '/medikamente/buscopan/': '/behandlung/medikamente/buscopan/',
  '/medikamente/caricol/': '/behandlung/medikamente/caricol/',
  '/medikamente/carmenthin/': '/behandlung/medikamente/carmenthin/',
  '/medikamente/daosin/': '/behandlung/medikamente/daosin/',
  '/medikamente/dulcolax-zaepfchen/':
    '/behandlung/medikamente/dulcolax-zaepfchen/',
  '/medikamente/dulcolax/': '/behandlung/medikamente/dulcolax/',
  '/allgemein/elotrans/': '/behandlung/medikamente/elotrans/',
  '/medikamente/endofalk/': '/behandlung/medikamente/endofalk/',
  '/medikamente/esomeprazol/': '/behandlung/medikamente/esomeprazol/',
  '/medikamente/fructaid-oder-fructosin-bei-fructoseintoleranz/':
    '/behandlung/medikamente/fructaid-und-fructosin/',
  '/medikamente/humira-nebenwirkungen/':
    '/behandlung/medikamente/humira-nebenwirkungen/',
  '/medikamente/humira/': '/behandlung/medikamente/humira/',
  '/medikamente/iberogast-dosierung/':
    '/behandlung/medikamente/iberogast-dosierung/',
  '/medikamente/iberogast-nebewirkungen-gefahren/':
    '/behandlung/medikamente/iberogast-nebewirkungen/',
  '/medikamente/iberogast/': '/behandlung/medikamente/iberogast/',
  '/medikamente/imodium/': '/behandlung/medikamente/imodium/',
  '/medikamente/laxoberal/': '/behandlung/medikamente/laxoberal/',
  '/medikamente/lefax-intens/': '/behandlung/medikamente/lefax-intens/',
  '/medikamente/lefax/': '/behandlung/medikamente/lefax/',
  '/medikamente/lopedium/': '/behandlung/medikamente/lopedium/',
  '/medikamente/loperamid-akut-dosierung-und-nebenwirkungen/':
    '/behandlung/medikamente/loperamid-akut/',
  '/medikamente/maaloxan/': '/behandlung/medikamente/maaloxan/',
  '/medikamente/macrogol/': '/behandlung/medikamente/macrogol/',
  '/medikamente/mcp-tropfen/': '/behandlung/medikamente/mcp-tropfen/',
  '/medikamente/medikamente-gegen-verstopfung/':
    '/behandlung/medikamente/medikamente-verstopfung/',
  '/medikamente/mesalazin/': '/behandlung/medikamente/mesalazin/',
  '/medikamente/movicol-junior/': '/behandlung/medikamente/movicol-junior/',
  '/medikamente/movicol/': '/behandlung/medikamente/movicol/',
  '/medikamente/nexium/': '/behandlung/medikamente/nexium/',
  '/medikamente/nystatin-suspension/':
    '/behandlung/medikamente/nystatin-suspension/',
  '/medikamente/nystatin/': '/behandlung/medikamente/nystatin/',
  '/medikamente/omeprazol-nebenwirkungen/':
    '/behandlung/medikamente/omeprazol-nebenwirkungen/',
  '/medikamente/omeprazol/': '/behandlung/medikamente/omeprazol/',
  '/medikamente/nebenwirkungen-pantoprazol/':
    '/behandlung/medikamente/pantoprazol-nebenwirkungen/',
  '/medikamente/pantoprazol/': '/behandlung/medikamente/pantoprazol/',
  '/medikamente/pantozol/': '/behandlung/medikamente/pantozol/',
  '/medikamente/pro-symbioflor/': '/behandlung/medikamente/pro-symbioflor/',
  '/medikamente/protonenpumpenhemmer/':
    '/behandlung/medikamente/protonenpumpenhemmer/',
  '/medikamente/remicade/': '/behandlung/medikamente/remicade/',
  '/medikamente/rifaximin/': '/behandlung/medikamente/rifaximin/',
  '/medikamente/sab-simplex/': '/behandlung/medikamente/sab-simplex/',
  '/medikamente/simeticon/': '/behandlung/medikamente/simeticon/',
  '/medikamente/simponi/': '/behandlung/medikamente/simponi/',
  '/medikamente/symbioflor-1/': '/behandlung/medikamente/symbioflor-1/',
  '/medikamente/symbioflor-2/': '/behandlung/medikamente/symbioflor-2/',
  '/medikamente/symbiolact/': '/behandlung/medikamente/symbiolact/',
  '/medikamente/tannacomp/': '/behandlung/medikamente/tannacomp/',
  '/behandlung/gallensteine-entfernen/':
    '/behandlung/operationen/gallensteine-entfernen/',
  '/behandlung/kolektomie/': '/behandlung/operationen/kolektomie/',
  '/behandlung/stuhltransplantation/':
    '/behandlung/operationen/stuhltransplantation/',
  '/untersuchungen/c13-atemtest/': '/diagnose/atemtest/c13-atemtest/',
  '/untersuchungen/h2-atemtest/': '/diagnose/atemtest/h2-atemtest/',
  '/blog/bluttest-biomarker-reizdarmsyndrom-diagnose-ohne-darmspieglung/':
    '/diagnose/bluttest/biomarker-reizdarmdiagnose/',
  '/untersuchungen/igg-tests/': '/diagnose/bluttest/igg-tests/',
  '/untersuchungen/abfuehrmittel-darmspiegelung/':
    '/diagnose/darmspiegelung/abfuehrmittel-darmspiegelung/',
  '/behandlung/darmreinigung/': '/diagnose/darmspiegelung/darmreinigung/',
  '/behandlung/darmsanierung-und-darmreinigung/':
    '/diagnose/darmspiegelung/darmsanierung-und-darmreinigung/',
  '/untersuchungen/darmspiegelung-vorbereitung/':
    '/diagnose/darmspiegelung/darmspiegelung-vorbereitung/',
  '/untersuchungen/darmspiegelung-ileokoloskopie/':
    '/diagnose/darmspiegelung/ileokoloskopie/',
  '/untersuchungen/magenspiegelung-gastroskopie/':
    '/diagnose/magenspiegelung/gastroskopie/',
  '/untersuchungen/lactoferrin-test-stuhl/':
    '/diagnose/stuhltest/lactoferrin-test/',
  '/untersuchungen/stuhlprobe/': '/diagnose/stuhltest/stuhlprobe/',
  '/untersuchungen/calprotectin-erhoeht/':
    '/erkrankungen/ced/calprotectin-erhoeht/',
  '/erkrankungen/ced/': '/erkrankungen/ced/ced-uebersicht/',
  '/behandlung/colitis-ulcerosa-behandlung/':
    '/erkrankungen/ced/colitis-ulcerosa/colitis-ulcerosa-behandlung/',
  '/symptome/colitis-ulcerosa-schub/':
    '/erkrankungen/ced/colitis-ulcerosa/colitis-ulcerosa-schub/',
  '/symptome/colitis-ulcerosa-symptome/':
    '/erkrankungen/ced/colitis-ulcerosa/colitis-ulcerosa-symptome/',
  '/erkrankungen/colitis-ulcerosa/':
    '/erkrankungen/ced/colitis-ulcerosa/colitis-ulcerosa-uebersicht/',
  '/ernaehrung/ernaehrung-bei-colitis-ulcerosa/':
    '/erkrankungen/ced/colitis-ulcerosa/ernaehrung-bei-colitis-ulcerosa/',
  '/erkrankungen/morbus-crohn-diagnose/':
    '/erkrankungen/ced/morbus-crohn/morbus-crohn-diagnose/',
  '/ernaehrung/morbus-crohn-ernaehrung/':
    '/erkrankungen/ced/morbus-crohn/morbus-crohn-ernaehrung/',
  '/symptome/morbus-crohn-schub/':
    '/erkrankungen/ced/morbus-crohn/morbus-crohn-schub/',
  '/symptome/morbus-crohn-symptome/':
    '/erkrankungen/ced/morbus-crohn/morbus-crohn-symptome/',
  '/behandlung/morbus-crohn-therapie/':
    '/erkrankungen/ced/morbus-crohn/morbus-crohn-therapie/',
  '/erkrankungen/morbus-crohn/':
    '/erkrankungen/ced/morbus-crohn/morbus-crohn-uebersicht/',
  '/erkrankungen/morbus-crohn-ursachen/':
    '/erkrankungen/ced/morbus-crohn/morbus-crohn-ursachen/',
  '/behandlung/trinknahrung/': '/erkrankungen/ced/trinknahrung/',
  '/symptome/fructoseintoleranz-symptome/':
    '/erkrankungen/intoleranz/fructose/fructoseintoleranz-symptome/',
  '/untersuchungen/fructoseintoleranz-test-selbsttest/':
    '/erkrankungen/intoleranz/fructose/fructoseintoleranz-testen/',
  '/erkrankungen/fructoseintoleranz/':
    '/erkrankungen/intoleranz/fructose/fructoseintoleranz/',
  '/ernaehrung/was-ist-gluten/':
    '/erkrankungen/intoleranz/gluten/gluten-uebersicht/',
  '/ernaehrung/glutenfrei-app/':
    '/erkrankungen/intoleranz/gluten/glutenfrei-app/',
  '/ernaehrung/glutenfreie-lebensmittel/':
    '/erkrankungen/intoleranz/gluten/glutenfreie-lebensmittel/',
  '/blog/suendenbock-gluten/':
    '/erkrankungen/intoleranz/gluten/suendenbock-gluten/',
  '/ernaehrung/weizenkleie/': '/erkrankungen/intoleranz/gluten/weizenkleie/',
  '/erkrankungen/zoeliakie-wie-erkennt-man-eine-glutenunvertraeglichkeit/':
    '/erkrankungen/intoleranz/gluten/zoeliakie-erkennen/',
  '/untersuchungen/zoeliakie-test-zur-diagnose/':
    '/erkrankungen/intoleranz/gluten/zoeliakie-testen/',
  '/erkrankungen/zoeliakie/': '/erkrankungen/intoleranz/gluten/zoeliakie/',
  '/ernaehrung/histamin-lebensmittelfuehrer/':
    '/erkrankungen/intoleranz/histamin/histamin-lebensmittelfuehrer/',
  '/ernaehrung/histaminintoleranz/histamin/':
    '/erkrankungen/intoleranz/histamin/histamin-uebersicht/',
  '/ernaehrung/histaminintoleranz/histaminarme-ernaehrung/':
    '/erkrankungen/intoleranz/histamin/histaminarme-ernaehrung/',
  '/ernaehrung/histaminintoleranz/histaminhaltige-lebensmittel/':
    '/erkrankungen/intoleranz/histamin/histaminhaltige-lebensmittel/',
  '/ernaehrung/histaminintoleranz/histaminintoleranz-test/':
    '/erkrankungen/intoleranz/histamin/histaminintoleranz-testen/',
  '/erkrankungen/histaminintoleranz/':
    '/erkrankungen/intoleranz/histamin/histaminintoleranz/',
  '/ernaehrung/laktose/':
    '/erkrankungen/intoleranz/lactose/lactose-uebersicht/',
  '/symptome/laktoseintoleranz-symptome/':
    '/erkrankungen/intoleranz/lactose/lactoseintoleranz-symptome/',
  '/untersuchungen/laktoseintoleranz-test-selbsttest/':
    '/erkrankungen/intoleranz/lactose/lactoseintoleranz/',
  '/erkrankungen/laktoseintoleranz/':
    '/erkrankungen/intoleranz/lactose/lactoseintoleranz/',
  '/erkrankungen/milcheiweissallergie/':
    '/erkrankungen/intoleranz/lactose/milcheiweissallergie/',
  '/erkrankungen/salicylatintoleranz/':
    '/erkrankungen/intoleranz/salicylatintoleranz/',
  '/ernaehrung/sorbit/': '/erkrankungen/intoleranz/sorbit/sorbit-uebersicht/',
  '/ernaehrung/sorbitintoleranz/':
    '/erkrankungen/intoleranz/sorbit/sorbitintoleranz/',
  '/ernaehrung/lebensmittelunvertraeglichkeit/':
    '/erkrankungen/intoleranz/unvertraeglichkeit/',
  '/ernaehrung/nahrungsmittelunvertraeglichkeiten-testen/':
    '/erkrankungen/intoleranz/unvertraeglichkeiten-testen/',
  '/erkrankungen/achalasie/': '/erkrankungen/oberbauch/achalasie/',
  '/symptome/aphten/': '/erkrankungen/oberbauch/aphten/',
  '/erkrankungen/eosinophile-oesophagitis/':
    '/erkrankungen/oberbauch/eosinophile-oesophagitis/',
  '/erkrankungen/hilfe-bei-gallensaeureverlustsyndrom/':
    '/erkrankungen/oberbauch/gallensaeureverlustsyndrom/',
  '/erkrankungen/hilfe-bei-gastritis/':
    '/erkrankungen/oberbauch/gastritis-behandlung/',
  '/erkrankungen/gastritis-dauer/': '/erkrankungen/oberbauch/gastritis-dauer/',
  '/ernaehrung/gastritis-ernaehrung/':
    '/erkrankungen/oberbauch/gastritis-ernaehrung/',
  '/erkrankungen/helicobacter-pylori/':
    '/erkrankungen/oberbauch/helicobacter-pylori/',
  '/erkrankungen/hereditaeres-angiooedem/':
    '/erkrankungen/oberbauch/hereditaeres-angiooedem/',
  '/erkrankungen/leberzirrhose/': '/erkrankungen/oberbauch/leberzirrhose/',
  '/symptome/magengeschwuer-symptome/':
    '/erkrankungen/oberbauch/magengeschwuer-symptome/',
  '/behandlung/magengeschwuer/': '/erkrankungen/oberbauch/magengeschwuer/',
  '/erkrankungen/magenschleimhautentzuendung/':
    '/erkrankungen/oberbauch/magenschleimhautentzuendung/',
  '/symptome/magenschmerzen/': '/erkrankungen/oberbauch/magenschmerzen/',
  '/erkrankungen/morbus-meulengracht-gilbert-syndrom/':
    '/erkrankungen/oberbauch/morbus-meulengracht-gilbert-syndrom/',
  '/erkrankungen/refluxoesophagitis-refluxkrankheit/':
    '/erkrankungen/oberbauch/refluxkrankheit/',
  '/erkrankungen/reizmagen/': '/erkrankungen/oberbauch/reizmagen/',
  '/erkrankungen/roemheld-syndrom/':
    '/erkrankungen/oberbauch/roemheld-syndrom/',
  '/erkrankungen/sooroesophagitis/':
    '/erkrankungen/oberbauch/sooroesophagitis/',
  '/behandlung/speiseroehrenentzuendung-behandlung/':
    '/erkrankungen/oberbauch/speiseroehrenentzuendung-behandlung/',
  '/erkrankungen/speiseroehrenkrebs/':
    '/erkrankungen/oberbauch/speiseroehrenkrebs/',
  '/erkrankungen/chronische-typ-c-gastritis/':
    '/erkrankungen/oberbauch/typ-c-gastritis/',
  '/erkrankungen/zwerchfellbruch-hiatushernie-2/':
    '/erkrankungen/oberbauch/zwerchfellbruch-hiatushernie/',
  '/reizdarm/reizdarmsyndrom-ursachen-postinfektioeses-reizdarmsyndrom/':
    '/erkrankungen/reizdarm/postinfektioeses-reizdarmsyndrom/',
  '/reizdarm-app/': '/erkrankungen/reizdarm/reizdarm-app/',
  '/behandlung/behandlung/': '/erkrankungen/reizdarm/reizdarm-behandlung/',
  '/reizdarm/reizdarmsyndrom-bei-kindern/':
    '/erkrankungen/reizdarm/reizdarm-bei-kindern/',
  '/reizdarm/diagnose/': '/erkrankungen/reizdarm/reizdarm-diagnose/',
  '/reizdarm/ernaehrung/': '/erkrankungen/reizdarm/reizdarm-ernaehrung/',
  '/blog/haeufigkeit-reizdarmsyndrom/':
    '/erkrankungen/reizdarm/reizdarm-haeufigkeit/',
  '/reizdarm/reizdarmsyndrom-hausmittel/':
    '/erkrankungen/reizdarm/reizdarm-hausmittel/',
  '/behandlung/medikamente/': '/erkrankungen/reizdarm/reizdarm-medikamente/',
  '/reizdarm/stress-beim-reizdarmsyndrom/':
    '/erkrankungen/reizdarm/reizdarm-stress/',
  '/reizdarm/reizdarm-symptome/': '/erkrankungen/reizdarm/reizdarm-symptome/',
  '/untersuchungen/reizdarm-test/': '/erkrankungen/reizdarm/reizdarm-testen/',
  '/reizdarm-uebersicht/': '/erkrankungen/reizdarm/reizdarm-uebersicht/',
  '/symptome/aufstossen/': '/erkrankungen/symptome/aufstossen/',
  '/symptome/bauchkraempfe/': '/erkrankungen/symptome/bauchkraempfe/',
  '/symptome/blaehungen/': '/erkrankungen/symptome/blaehungen/',
  '/symptome/blut-im-stuhl/': '/erkrankungen/symptome/blut-im-stuhl/',
  '/symptome/durchfall/': '/erkrankungen/symptome/durchfall/',
  '/symptome/farbe/': '/erkrankungen/symptome/farbe/',
  '/symptome/flatulenz/': '/erkrankungen/symptome/flatulenz/',
  '/symptome/gelber-stuhlgang/': '/erkrankungen/symptome/gelber-stuhlgang/',
  '/symptome/harter-stuhlgang/': '/erkrankungen/symptome/harter-stuhlgang/',
  '/symptome/meteorismus/': '/erkrankungen/symptome/meteorismus/',
  '/symptome/oberbauchschmerzen/': '/erkrankungen/symptome/oberbauchschmerzen/',
  '/symptome/reflux/': '/erkrankungen/symptome/reflux/',
  '/symptome/schleim-im-stuhl/': '/erkrankungen/symptome/schleim-im-stuhl/',
  '/erkrankungen/schluckstoerungen-dysphagien/':
    '/erkrankungen/symptome/schluckstoerungen/',
  '/symptome/schwarzer-stuhlgang-teerstuhl/':
    '/erkrankungen/symptome/schwarzer-stuhl/',
  '/allgemein/sodbrennen-ursachen/':
    '/erkrankungen/symptome/sodbrennen-ursachen/',
  '/symptome/sodbrennen-2/': '/erkrankungen/symptome/sodbrennen/',
  '/symptome/stinkende-blaehungen/':
    '/erkrankungen/symptome/stinkende-blaehungen/',
  '/symptome/unterbauchschmerzen/':
    '/erkrankungen/symptome/unterbauchschmerzen/',
  '/symptome/unterleibsschmerzen-links/':
    '/erkrankungen/symptome/unterleibsschmerzen-links/',
  '/symptome/verstopfung/': '/erkrankungen/symptome/verstopfung/',
  '/symptome/voellegefuehl/': '/erkrankungen/symptome/voellegefuehl/',
  '/erkrankungen/bandwurm/': '/erkrankungen/unterbauch/bandwurm/',
  '/erkrankungen/blinddarmentzuendung/':
    '/erkrankungen/unterbauch/blinddarmentzuendung/',
  '/erkrankungen/blinddarmreizung/':
    '/erkrankungen/unterbauch/blinddarmreizung/',
  '/behandlung/alternativmedizin/candida-albicans/':
    '/erkrankungen/unterbauch/candida-albicans/',
  '/erkrankungen/darmentzuendung/': '/erkrankungen/unterbauch/darmentzuendung/',
  '/erkrankungen/darmerkrankungen/':
    '/erkrankungen/unterbauch/darmerkrankungen/',
  '/behandlung/darmflora-aufbauen/':
    '/erkrankungen/unterbauch/darmflora-aufbauen/',
  '/erkrankungen/darmflora-mikrobiom-und-reizdarm/':
    '/erkrankungen/unterbauch/darmflora-reizdarm/',
  '/erkrankungen/darmkrebs/': '/erkrankungen/unterbauch/darmkrebs/',
  '/untersuchungen/darmkrebsvorsorge/':
    '/erkrankungen/unterbauch/darmkrebsvorsorge/',
  '/erkrankungen/darmverschluss/': '/erkrankungen/unterbauch/darmverschluss/',
  '/ernaehrung/ernaehrung-bei-divertikulitis/':
    '/erkrankungen/unterbauch/divertikulitis-ernaehrung/',
  '/erkrankungen/divertikulose-und-divertikulitis-ausstuelpungen-im-darm/':
    '/erkrankungen/unterbauch/divertikulose/',
  '/erkrankungen/duenndarmfehlbesiedlung-sibo/':
    '/erkrankungen/unterbauch/duenndarmfehlbesiedlung/',
  '/erkrankungen/dysbiose/': '/erkrankungen/unterbauch/dysbiose/',
  '/erkrankungen/gaerungsdyspepsie/':
    '/erkrankungen/unterbauch/gaerungsdyspepsie/',
  '/symptome/gallensteine-symptome/':
    '/erkrankungen/unterbauch/gallensteine-symptome/',
  '/erkrankungen/gallensteine/': '/erkrankungen/unterbauch/gallensteine/',
  '/symptome/haemorrhoiden/': '/erkrankungen/unterbauch/haemorrhoiden/',
  '/erkrankungen/kurzdarmsyndrom/': '/erkrankungen/unterbauch/kurzdarmsyndrom/',
  '/erkrankungen/leaky-gut-syndrom-und-darmbarriere/':
    '/erkrankungen/unterbauch/leaky-gut/',
  '/erkrankungen/mikroskopische-kolitis/':
    '/erkrankungen/unterbauch/mikroskopische-kolitis/',
  '/erkrankungen/morbus-whipple/': '/erkrankungen/unterbauch/morbus-whipple/',
  '/erkrankungen/parasiten-im-darm/':
    '/erkrankungen/unterbauch/parasiten-im-darm/',
  '/erkrankungen/pilze-im-darm/': '/erkrankungen/unterbauch/pilze-im-darm/',
  '/ernaehrung/candida-albicans-diaet/':
    '/ernaehrung/diaeten/candida-albicans-diaet/',
  '/ernaehrung/ernaehrungsumstellung/':
    '/ernaehrung/diaeten/ernaehrungsumstellung/',
  '/ernaehrung/intervallfasten-16-8/': '/ernaehrung/diaeten/intervallfasten/',
  '/blog/low-fodmap-vs-konventionelle-ernaehrungsumstellung/':
    '/ernaehrung/diaeten/konventionell-vs-low-fodmap/',
  '/ernaehrung/low-fodmap-diaet/': '/ernaehrung/diaeten/low-fodmap/',
  '/ernaehrung/ballaststoffe/': '/ernaehrung/lebensmittel/ballaststoffe/',
  '/ernaehrung/basische-lebensmittel/':
    '/ernaehrung/lebensmittel/basische-lebensmittel/',
  '/ernaehrung/flohsamen/': '/ernaehrung/lebensmittel/flohsamen/',
  '/ernaehrung/flohsamenschalen/': '/ernaehrung/lebensmittel/flohsamenschalen/',
  '/ernaehrung/inulin/': '/ernaehrung/lebensmittel/inulin/',
  '/ernaehrung/lektine/': '/ernaehrung/lebensmittel/lektine/',
  '/medikamente/omni-biotic/': '/ernaehrung/lebensmittel/omni-biotic/',
  '/ernaehrung/praebiotika/': '/ernaehrung/lebensmittel/praebiotika/',
  '/medikamente/probiotika-vorteile-und-wirkung/':
    '/ernaehrung/lebensmittel/probiotika-uebersicht/',
  '/medikamente/probiotika-wirkung/':
    '/ernaehrung/lebensmittel/probiotika-wirkung/',
  '/ernaehrung/probiotischer-joghurt/':
    '/ernaehrung/lebensmittel/probiotischer-joghurt/',
  '/ernaehrung/low-fodmap-liste-pdf/': '/ernaehrung/rezepte/low-fodmap-liste/',
  '/ernaehrung/blaubeerkuchen-low-fodmap/':
    '/ernaehrung/rezepte/blaubeerkuchen-low-fodmap/',
  '/ernaehrung/bretonische-galette-low-fodmap-rezept/':
    '/ernaehrung/rezepte/bretonische-galette-low-fodmap/',
  '/rezepte/chicken-curry/': '/ernaehrung/rezepte/chicken-curry/',
  '/ernaehrung/glasnudel-sommer-roellchen-low-fodmap-sushi/':
    '/ernaehrung/rezepte/glasnudel-sommerrollen-low-fodmap/',
  '/ernaehrung/himbeer-erdmandel-macarons-low-fodmap-rezept/':
    '/ernaehrung/rezepte/himbeer-erdmandel-macarons-low-fodmap/',
  '/ernaehrung/kaesekuchen-mit-karamellsosse-low-fodmap-rezept/':
    '/ernaehrung/rezepte/kaesekuchen-low-fodmap/',
  '/ernaehrung/karotten-orangenoel-und-petersilienkartoffeln-low-fodmap-rezept/':
    '/ernaehrung/rezepte/karotten-petersilienkartoffeln-low-fodmap/',
  '/ernaehrung/knuspriges-granola-zum-fruehstueck-low-fodmap-rezept/':
    '/ernaehrung/rezepte/knuspriges-granola-low-fodmap/',
  '/rezepte/low-fodmap-brot-basic/':
    '/ernaehrung/rezepte/low-fodmap-brot-basic/',
  '/ernaehrung/low-fodamp-ratatouille/':
    '/ernaehrung/rezepte/ratatouille-low-fodamp/',
  '/ernaehrung/reisflockenporridge-mit-himbeerensosse-low-fodmap-rezept/':
    '/ernaehrung/rezepte/reisflockenporridge-mit-himbeersosse-low-fodmap/',
  '/ernaehrung/luftige-schokoladen-riegel-low-fodmap-rezept/':
    '/ernaehrung/rezepte/schokoladenriegel-low-fodmap/',
  '/blog/schutz-der-darmflora/': '/ernaehrung/tipps/darmflora-schuetzen/',
  '/blog/wie-darmbakterien-mit-unserem-gehirn-kommunizieren/':
    '/magazin/darmbakterien-hirm-kommunikation/',
  '/blog/haut-darm-hirn-erfahrungsbericht/':
    '/magazin/erfahrungsbericht-haut-darm-hirn/',
  '/blog/in-4-wochen-zum-entspannten-bauch-anna-erzaehlt/':
    '/magazin/erfahrungsbericht-in-4-wochen-zum-entspannten-bauch/',
  '/ernaehrung/leben-mit-allergien-und-nahrungsmittelunvertraeglichkeiten/':
    '/magazin/erfahrungsbericht-leben-mit-allergien-und-nahrungsmittelunvertraeglichkeiten/',
  '/blog/mein-leben-mit-reizdarm-der-leidensweg-einer-betroffenen/':
    '/magazin/erfahrungsbericht-mein-leben-mit-reizdarm/',
  '/reizdarm/reizdarm-reizwort/meine-ernaehrung-ist-meine-medizin/':
    '/magazin/erfahrungsbericht-meine-ernaehrung-ist-meine-medizin/',
  '/blog/erfahrungsbericht-sarah/': '/magazin/erfahrungsbericht-sarah/',
  '/blog/von-der-entdeckung-meines-bauchhirns/':
    '/magazin/erfahrungsbericht-von-der-entdeckung-meines-bauchhirns/',
  '/erkrankungen/fatigue-syndrom/': '/magazin/fatigue-syndrom/',
  '/erkrankungen/fibromyalgie/': '/magazin/fibromyalgie/',
  '/blog/frauen-und-verdauungsprobleme/':
    '/magazin/frauen-und-verdauungsprobleme/',
  '/blog/zyklus-periode-und-verdauung/': '/magazin/periode-und-verdauung/',
  '/reizdarm-reizwort/': '/magazin/reizdarm-reizwort/',
  '/erkrankungen/somatoforme-stoerungen/': '/magazin/somatoforme-stoerungen/',
  '/symptome/wechseljahresbeschwerden/': '/magazin/wechseljahresbeschwerden/',
  '/reizdarm-selbsttest/': '/selbsttest/reizdarm-test/',
  '/untersuchungen/histaminintoleranz-test/':
    '/erkrankungen/intoleranz/histamin/histaminintoleranz-testen/',
  '/ernaehrung/ernaehrung/': '/ernaehrung/',
  '/intoleranz-test/':
    '/erkrankungen/intoleranz/fructose/fructoseintoleranz-testen/',
  '/low-fodmap-rezepte/': '/ernaehrung/rezepte/',
  '/symptome/sodbrennen-hilft-gegen-reflux/':
    '/erkrankungen/symptome/sodbrennen/',
  '/ernaehrung/entgiftung/': '/behandlung/alternativ/entgiftungskur/',
  '/behandlung/alternativmedizin/schuessler-salze/nr-3-ferrum-phosphoricum/':
    '/behandlung/alternativ/schuessler-ueberblick/',
  '/symptome/zoeliakie-wie-erkennt-man-eine-glutenunvertraeglichkeit/':
    '/erkrankungen/intoleranz/gluten/zoeliakie-erkennen/',
  '/ernaehrung/low-fodmap-brot-basic/':
    '/ernaehrung/rezepte/low-fodmap-brot-basic/',
  '/ernaehrung/histaminarme-ernaehrung/':
    '/erkrankungen/intoleranz/histamin/histaminarme-ernaehrung/',
  '/ernaehrung/histaminhaltige-lebensmittel/':
    '/erkrankungen/intoleranz/histamin/histaminhaltige-lebensmittel/',
  '/erkrankungen/aphthen/': '/erkrankungen/oberbauch/aphten/',
  '/reizdarm/behandlung/': '/erkrankungen/reizdarm/reizdarm-behandlung/',
  '/medikamente/antihistaminika/': '/behandlung/medikamente/antihistaminika/',
  '/reizdarm/reizdarm-reizwort/heilpraktiker-sind-auch-ueberfordert/': '/',
  '/reizdarm/medikamente/': '/erkrankungen/reizdarm/reizdarm-medikamente/',
  '/erkrankungen/haemorrhoiden/': '/erkrankungen/unterbauch/haemorrhoiden/',
  '/reizdarm/reizdarm-reizwort/reizdarm/': '/',
  '/symptome/sodbrennen/': '/erkrankungen/symptome/sodbrennen/',
  '/therapieangebot/':
    'https://cara.app.link/wdEbvMwCh2?utm_source=web&utm_medium=intext&utm_campaign=de',
  '/therapieangebot-orig/':
    'https://cara.app.link/wdEbvMwCh2?utm_source=web&utm_medium=intext&utm_campaign=de',
  '/therapieangebot/?source=hyperlink':
    'https://cara.app.link/wdEbvMwCh2?utm_source=web&utm_medium=intext&utm_campaign=de',
  '/therapieangebot/?source=PSlink':
    'https://cara.app.link/wdEbvMwCh2?utm_source=web&utm_medium=intext&utm_campaign=de',
  '/therapieangebot/?utm_source=darmflora':
    'https://cara.app.link/wdEbvMwCh2?utm_source=web&utm_medium=intext&utm_campaign=de',
};
